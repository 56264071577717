#footer {
  /*  background: url("/public/assets/img/footer-bg.jpg") center center no-repeat; */
    color: #fff;
    font-size: 14px;
    position: relative;
  }
  #footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(26, 46, 64, 0.85);
    z-index: 1;
  }
  #footer .footer-top {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 80px 0;
  }
  #footer .footer-top h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  #footer .footer-top p {
    font-size: 15;
    font-style: italic;
    margin: 30px 0 0 0;
    padding: 0;
  }
  #footer .footer-top .footer-newsletter {
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
  }
  #footer .footer-top .footer-newsletter form {
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #fdc134;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #fcb102;
  }
  #footer .footer-top .social-links {
    margin-top: 30px;
  }
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #284864;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
    background: #fcb102;
    color: #fff;
    text-decoration: none;
  }
  #footer .footer-bottom {
  /*  border-top: 1px solid #1a2e40; */
    z-index: 2;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #footer .disclaimer {
    text-align: center;
    float: left;
    margin-top: 10px;
  }
  #footer .copyright {
    text-align: center;
    float: left;
  }
  #footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
  }
  @media (max-width: 768px) {
    #footer .copyright, #footer .credits {
      padding: 5px;
      float: none;
      text-align: ceneter;
    }
  }