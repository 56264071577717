
#hero {
    width: 100%;
    padding: 0 0 0 0;
    background: #435170;
    background: linear-gradient(180deg, #435170 0%, #435174 35%, #414F6D 100%);
  }

  #hero h1 {
    margin: 0 0 15px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #d2e0ed;
    font-family: "Poppins", sans-serif;
  }
  #hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 22px;
  }
  #hero .btn-get-started {
    color: #414F6D;
    border-radius: 50px;
    padding: 8px 35px 10px 35px;
    border: 2px solid #fdc134;
    transition: all ease-in-out 0.3s;
    display: inline-block;
    background: #fdc134;
  }
  #hero .btn-get-started:hover {
    background: transparent;
    color: #fff;
  }
  @media (max-width: 991px) {
    #hero .hero-img {
      text-align: center;
    }
    #hero .hero-img img {
      width: 44%;
    }
  }
  @media (max-width: 768px) {
    #hero {
      -moz-text-align-last: center;
      text-align-last: center;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    #hero .hero-img img {
      width: 70%;
    }
  }
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
  }

  .custom-btn{
    background-color:#fdc134 !important;
    color:#fff !important;
}

.button {
  border-radius: 4px !important;
  background-color: rgb(247, 147, 26) !important;
  border: none !important;
  color: #FFFFFF !important;
  text-align: center !important;
  font-size: 20px !important;
  padding: 8px 35px 10px 35px !important;
  transition: all 0.5s !important;
  cursor: pointer !important;
  margin: 5px !important;
}

.button span {
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  transition: 0.5s !important;
}

.button span:after {
  content: '\00bb' !important;
  position: absolute !important;
  opacity: 0 !important;
  top: 0 !important;
  right: -20px !important;
  transition: 0.5s !important;
}

.button:hover span {
  padding-right: 25px !important;
}

.button:hover span:after {
  opacity: 1 !important;
  right: 0 !important;
}
